const kana_stol = {
	"ぁ": "あ",
	"ぃ": "い",
	"ぅ": "う",
	"ぇ": "え",
	"ぉ": "お",
	"っ": "つ",
	"ゃ": "や",
	"ゅ": "ゆ",
	"ょ": "よ",
	"ゎ": "わ",
	"ァ": "ア",
	"ィ": "イ",
	"ゥ": "ウ",
	"ェ": "エ",
	"ォ": "オ",
	"ッ": "ツ",
	"ャ": "ヤ",
	"ュ": "ユ",
	"ョ": "ヨ",
	"ヮ": "ワ",
}

const Zenkaku = {

	normalize(str) {
		return String(str != null ? str : "")
		.replace(/[\u200B\u200C\u200D\uFEFF\u2028\u2029\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F]/g, " ")
		.trim();
	},

	hankaku(str) {
		return Zenkaku.normalize(str)
		.replace(/[！-～]/g, m => String.fromCharCode(m.charCodeAt(0) - 65248))
		.replace(/[\u2212\uff0d\u30fc\u2012\u2013\u2014\u2015\u2500]/g, "-")
		.replace(/\u3000/g, " ")
		.trim();
	},

	hiragana(str) {
		return str.replace(/[\u30a1-\u30f6]/g, m => String.fromCharCode(m.charCodeAt(0) - 0x60));
	},

	katakana(str) {
		return str.replace(/[\u3041-\u3096]/g, m => String.fromCharCode(m.charCodeAt(0) + 0x60));
	},

	regularKana(str) {
		return str.replace(/[\u3041-\u3096\u30a1-\u30f6]/g, m => (m in kana_stol) ? kana_stol[m] : m);
	}
}

export default Zenkaku
